import React, {useState} from "react";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {Formik} from "formik";
import {graphql} from "gatsby";
import RenderBlocks from "../components/renderBlocks";
import * as Yup from "yup";
import SEO from "../components/seo";

export const query = graphql`
    query ContactPageQuery {
        datoCmsPage(url: {eq: "contact"}) {
            title
            content {
                ... on DatoCmsCallToAction {
                    id
                    model {
                        apiKey
                    }
                    contentWidth
                    content
                    addLeftBorder
                }
            }
        }
    }
`

const Contact = ({data}) => {
    const {
        datoCmsPage: {
            title,
            content
        },
    } = data;

    const [successSubmit, setSuccessSubmit] = useState(false)
    const [isError, setIsError] = useState(false)

    const contactValidation = () => Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string().email('Invalid email type, please add a valid email.')
            .required('Email address is required.'),
        number: Yup.string().notRequired().nullable(),
        message: Yup.string().required('Message is required.'),
    })


    const handleFormSubmit = (values) => {
        const formData = new FormData();

        Object.entries(values).forEach((entry) => {
            const [key, value] = entry;
            formData.append(key, value)
        });

        return fetch("https://getform.io/f/33de37e0-98ae-4d86-a20f-b88251da9108", {
            method: "POST",
            body: formData,
        })
            .then((response) => {
                if (response.status <= 299) {
                    setSuccessSubmit(true)
                    return;
                }
                setIsError(true)
            })
            .catch(() => setIsError(true))
    }

    if (successSubmit) {
        return (
            <div className={'contact-page'}>
                <div className={'container pb-5'}>
                    <RenderBlocks fields={content}/>

                    <div className={'success-message pt-4'}>
                        Thank you for your message, I will get back to you soon as possible.
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <SEO title={title} />

            <div className={'contact-page'}>
                <div className={'container pb-5'}>
                    <RenderBlocks fields={content}/>
                    <Formik
                        initialValues={{name: '', number: '', email: '', message: ''}}
                        onSubmit={(values, {setSubmitting}) => {
                            if (isError) setIsError(false)
                            handleFormSubmit(values)
                                .finally(() => setSubmitting(false))
                        }}
                        validationSchema={contactValidation}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                          }) => (
                            <Form onSubmit={handleSubmit} className={'pt-5'}>
                                <Row>
                                    <Col md={6} className={'mb-4'}>
                                        <Form.Group>
                                            <Form.Label>Full name</Form.Label>
                                            <Form.Control
                                                name={'name'}
                                                autoComplete={'off'}
                                                type="text"
                                                value={values.name || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.name && !errors.name}
                                                isInvalid={!!errors.name}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className={'mb-4'}>
                                        <Form.Group>
                                            <Form.Label>Phone number</Form.Label>
                                            <Form.Control
                                                name={'number'}
                                                autoComplete={'off'}
                                                type="tel"
                                                value={values.number || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.number && !errors.number}
                                                isInvalid={!!errors.number}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className={'mb-4'}>
                                        <Form.Group>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                name={'email'}
                                                autoComplete={'off'}
                                                type="email"
                                                value={values.email || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={!!errors.email}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={12} className={'mb-4'}>
                                        <Form.Group>
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                name={'message'}
                                                type="text"
                                                autoComplete={'off'}
                                                as={'textarea'}
                                                rows={5}
                                                value={values.message || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isValid={touched.message && !errors.message}
                                                isInvalid={!!errors.message}
                                            />
                                        </Form.Group>
                                    </Col>

                                    {isError && (
                                        <Col>
                                            <Alert variant={'danger'}>
                                                There as been a error submitted your request. Please retry your
                                                submission.
                                            </Alert>
                                        </Col>
                                    )}
                                </Row>

                                <Button
                                    type={'submit'}
                                    variant={'outline-dark'}
                                    className={'rounded-pill px-5 py-2'}
                                    disabled={isSubmitting}
                                >Submit</Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export default Contact